import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "employee-management-integrations"
    }}>{`Employee management integrations`}</h1>
    <p>{`Managing employees across Planday and other systems is a key use case and linked to a variety of systems like HR, payroll, communication and e-learning.`}</p>
    <p>{`Use the basic actions to create, read or update (and deactivate) employees.
The GET employees endpoint is a list endpoint that returns all active employees with basic data. The GET employees by ID endpoint returns more detailed information about the employees - including custom fields.`}</p>
    <h3 {...{
      "id": "special-fields"
    }}>{`Special fields`}</h3>
    <p>{`Some employee fields require some extra attention. We call these special fields. To manage the special fields for both read, create and update actions you have to add the dedicated scopes to you API application as well as including the fields in the URL as path parameters. `}</p>
    <p>{`Special fields:`}</p>
    <ul>
      <li parentName="ul">{`Ssn (Social security number)`}</li>
      <li parentName="ul">{`BankAccount`}</li>
      <li parentName="ul">{`BirthDate`}</li>
    </ul>
    <h3 {...{
      "id": "custom-fields"
    }}>{`Custom fields`}</h3>
    <p>{`In Planday, managers can create new employee fields if they want something specific to be shown for each employee. These fields are what we call custom fields.`}</p>
    <p>{`Custom fields are created with a certain type that gives information about the data which can be inputted into the field. The supported types are:`}</p>
    <ul>
      <li parentName="ul">{`in text form`}</li>
      <li parentName="ul">{`in numeric form`}</li>
      <li parentName="ul">{`as an enabled or disabled state`}</li>
      <li parentName="ul">{`as a calendar date`}</li>
      <li parentName="ul">{`as an uploaded picture `}</li>
    </ul>
    <p>{`Employee custom fields are returned in the GET employee by Id endpoint.`}</p>
    <h4 {...{
      "id": "create-or-update-custom-field-values-on-an-employee"
    }}>{`Create or update custom field values on an employee`}</h4>
    <p>{`You can also specify a custom field value when creating or updating an employee. For this you will need to follow the steps below:`}</p>
    <ol>
      <li parentName="ol">{`Query the GET Employee field definitions endpoint to get a list of all the possible custom fields on the specific portal. All custom fields is listed as `}<inlineCode parentName="li">{`“custom_xxxx”`}</inlineCode>{` in the properties collection, where “xxxx” is a number.`}</li>
    </ol>
    <p><img alt="Get employee" src={require("./images/getemployee.png")} />{`
2. When creating/updating an employee, use the custom field id (`}<inlineCode parentName="p">{`"custom_xxxx"`}</inlineCode>{`) as key of the field you want to update.`}</p>
    <p><img alt="Create update employee" src={require("./images/createemployee.png")} />{`
Below you’ll find an example of the request to create or update an employee, including custom fields.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
 "firstName": "First",
 "lastName": "Last",
 "userName": "User123",
 "cellPhone": "1234567",
 "street1": "Example",
 "zip": "12332",
 "city": "ExampleCity",
 "phone": "23213232",
 "gender": "Female",
 "email": "x-planday123123@planday.com",
 "departments": [147991],
 "employeeGroups": [266955],
 "custom_146688": true,
 "custom_164605": 5
}
`}</code></pre>
    <h3 {...{
      "id": "updating-employees-pay-rates-and-contract-rules"
    }}>{`Updating employees’ pay rates and contract rules`}</h3>
    <h4 {...{
      "id": "pay-rates-and-salaries"
    }}>{`Pay rates and salaries`}</h4>
    <p>{`You can add pay rates and salaries to employees in Planday by using the Pay API (Please note that rates are linked to employee groups in Planday). The API also supports other related functionality like read rate history for an employee, read and update monthly salaries and allocation of the cost in schedule.
Use the `}<inlineCode parentName="p">{`PUT /pay/v{version}/payrates/employeeGroups/{employeeGroupId} `}</inlineCode>{`to specify an employee’s pay rate in a given employee group or the `}<inlineCode parentName="p">{`PUT /pay/v{version}/salaries/employees/{employeeId}`}</inlineCode>{` to specify a salaried employee’s salary.`}</p>
    <h4 {...{
      "id": "salary-codes"
    }}>{`Salary codes`}</h4>
    <p>{`When managing the rates and salaries, you should also consider to handle the salary codes, which are used to identify pay items for payroll, in your requests. If the salary codes are added by managers inside Planday, you should start your update operations by fetching the existing values and use them when making the update request.`}</p>
    <h4 {...{
      "id": "contracted-hours"
    }}>{`Contracted hours`}</h4>
    <p>{`The Contract Rules API makes it possible to read the contract rules created in Planday and assign them to employees. A contract rule defines an employee’s contracted hours per week, month or year. The intervals are set up in Planday settings. Use the `}<inlineCode parentName="p">{`PUT /contractrules/v{version}/employees/{employeeId}`}</inlineCode>{` to update an employee with a given contract rule.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      